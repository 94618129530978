<template>
    <div ref="$ScrollRoot" class="card-main-global-lazy" :class="mode || 'default-section'">
        <div
            v-if="!intersected && !ignoreLazy"
            class="card-main-global-lazy__fallback"
            :style="{
                '--full-height': $config.public.variant === 'megadescuentos' ? '401px' : '295px',
                '--full-mobile-height': $config.public.variant === 'megadescuentos' ? '353px' : '251px',
            }"
            :class="mode"
        >
            {{ title || (!noPlaceholder && 'Loading...') || '' }}
        </div>

        <slot v-else />
    </div>
</template>

<script lang="ts" setup>
const props = defineProps({
    ignoreLazy: Boolean,
    title: String,
    mode: String,
    noPlaceholder: Boolean,
    url: String,
})
const emits = defineEmits(['intersected'])

const $ScrollRoot = ref() as Ref<HTMLDivElement>

const intersected = ref(false)
const { $scrollObserver } = useNuxtApp()
const intersectHandler = (data: IntersectionObserverEntry) => {
    if (data.isIntersecting) {
        intersected.value = true

        emits('intersected')

        $scrollObserver.remove(data.target)
    }
}
onMounted(() => {
    if (!props.ignoreLazy) {
        nextTick(() => {
            $scrollObserver.addTarget($ScrollRoot.value, intersectHandler)
        })
    }
})
</script>

<style lang="postcss" scoped>
.card-main-global-lazy {
    @apply h-full;
    &__fallback {
        @apply flex h-full items-center justify-center rounded-xl bg-white p-3 text-center text-lg text-gray-800;
        &.global {
            @apply h-52 w-full;
        }
        &.featured-full {
            @apply h-[--full-mobile-height] w-[192px] lg:h-[--full-height] lg:w-[208px];
        }
        &.featured-short {
            @apply h-[243px] w-[192px] lg:h-[287px] lg:w-[208px];
        }
        &.comments-box {
            @apply h-[43px];
        }
        &.comments-list {
            @apply h-[700px];
        }
        &.default-section {
            @apply h-[400px];
        }
    }
}
</style>
